.team-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
}

.team-container {
  width: 100%;
  padding: 2rem;
}

.main-title {
  margin-top: 15rem;
  font-size: 5.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
