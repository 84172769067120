.hero-user-quote {
  font-size: 2rem;
  font-weight: 500;
  line-height: 3rem;
  font-style: italic;
  color: var(--white);
  max-width: 46rem;
  text-transform: capitalize;
  margin-bottom: 2.5rem;
  min-height: 150px;
  display: flex;
  align-items: flex-end;
}

.card-images {
  display: flex;
  width: 7rem;
  height: 7rem;
  gap: 2rem;
}

.hero-feedback {
  display: flex;
  align-items: center;
}
.hero-feedback > div {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.hero-feedback > div p {
  margin: 0;
}
.hero-feedback img {
  width: 7rem;
  height: 7rem;
}
.hero-feedback-name {
  font-weight: bold;
  color: #ffffff;
  font-size: 2rem;
  line-height: 3rem;
}
.hero-feedback-position {
  font-weight: normal;
  color: #3eb6fb;
  font-size: 2rem;
  line-height: 3rem;
}
.dots-container {
  display: flex;
  gap: 8px;
  margin-top: 4rem;
}
.dot {
  height: 8px;
  width: 8px;
  outline: 1px solid #3eb6fb;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
.dot.active {
  background-color: #ffffff;
}

@media screen and (max-width: 1250px) {
  .hero-user-quote {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .hero-card {
    min-height: 200px;
  }
}

@media screen and (max-width: 750px) {
  .hero-user-quote {
    font-size: 2rem;
    line-height: 2rem;
    min-height: 100px;
  }
  .hero-feedback-name {
    font-size: 2rem;
    line-height: 2rem;
  }
  .hero-feedback img {
    width: 50px;
    height: 50px;
  }
  .hero-feedback-position {
    font-size: 2rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .hero-user-quote,
  .hero-feedback-name,
  .hero-feedback-position {
    font-size: 14px;
  }
}
