header {
  width: 100%;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(30px);
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1.5rem 0;
}
.header-content img {
  width: 6.5rem;
  height: auto;
  object-fit: contain;
}
.nav-menu,
.menu-desktop {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5rem;
}
.menu-desktop a,
.menu-desktop p {
  color: var(--white);
  font-size: 1.65rem;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  cursor: pointer;
}
nav img {
  display: none;
  cursor: pointer;
  width: 45px;
}
.burger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}
.burger:focus {
  outline: none;
}
.line {
  width: 30px;
  height: 0.25rem;
  background: #effffa;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}
.burger.open .line:first-child {
  transform: rotate(45deg);
}
.burger.open .line:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}
.burger.open .line:nth-child(3) {
  transform: rotate(-45deg);
}
.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 70px 30px;
  background: #070b0e;
  transform: translateX(100%);
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
}
.open{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.menu p,
.menu a {
  color: var(--white);
  font-weight: 600;
  line-height: 130%;
  cursor: pointer;
  font-size: 25px;
  margin: 10px 0;
}

@media screen and (max-width: 750px) {
  .menu-desktop {
    display: none;
  }
  .burger {
    display: flex;
  }
  .menu.open {
    display: flex;
    transform: translateX(0);
    min-width: 250px;
    padding-top: 90px;
  }
  nav p,
  nav a {
    display: block;
  }
}

@media (max-width: 576px) {
  .menu {
    width: 100%;
  }
  nav img {
    position: absolute;
    top: 15px;
    display: block;
    left: 25px;
  }
}
