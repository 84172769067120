.overview-section {
  width: 100%;
  position: relative;
  background-color: #000000;
}
.overview-grid {
  width: 100%;
}
.overview {
  display: flex;
  flex-direction: column;
}
.overview-grid .applications-content {
  justify-content: center;
}
.applications-content .overview-description {
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 3rem;
  color: #ffffff;
}
.overview-title {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 4.3rem;
  color: #ffffff;
}
.elements {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}
.heat-mapping {
  font-size: 4rem;
  line-height: 5rem;
}
.heat-map-image {
  height: unset;
}
.employee-experience {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1250px) {
  .overview-grid {
    grid-template-columns: 1fr;
  }
  .overview-section {
    min-height: 825px;
    padding-top: 0;
  }
  .overview-section .overview-grid {
    grid-gap: 20px;
  }
}

@media screen and (max-width: 750px) {
  .overview-section {
    min-height: 875px;
  }
  .overview-title {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
  .overview-section .overview-grid {
    grid-gap: 10px;
  }
  .applications-content .overview-description {
    text-align: left;
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .heat-mapping {
    font-size: 3rem;
    line-height: 3.2rem;
  }
}