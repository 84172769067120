footer {
  width: 100%;
  padding: 0 0 5rem 0;
  min-height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer p {
  color: rgba(186, 192, 206);
  text-align: left;
  font-size: 1.1rem;
  margin: 0;
  flex: 1;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
}
.footer-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  flex: 1;
  max-width: 50%;
}
.footer-links a {
  font-size: 1.1rem;
  color: rgba(186, 192, 206);
  text-decoration: underline;
}
