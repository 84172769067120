.contact-section {
  min-height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.contact-container {
  width: 50%;
  max-width: 800px;
  padding: 2rem;
}

.main-title {
  margin-top: 5rem;
  font-size: 5.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 5rem;
}

.form-row {
  display: flex;
  gap: 1.5rem;
  width: 100%;
}

.recaptcha {
  min-width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.recaptcha-error-message {
  color: #ff4444;
  font-size: 1.25rem;
  margin-top: 0.5rem;
}

.submit-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  margin-top: 0.5rem;
}

.submit-error {
  color: #ff4444;
}

.submit-success {
  color: #4BB543;
}

.submit-button {
  width: fit-content;
  padding: 1rem 2rem;
  margin-top: 2rem;
  background-color: #3eb6fb;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.submit-button:hover {
  background-color: #2b9fe3;
}

@media (max-width: 800px) {
  .contact-container {
    width: 100%;
    padding: 1.5rem;
  }

  .form-row {
    flex-direction: column;
    gap: 1rem;
  }
}
